import { useQuery } from '@apollo/client';
import { fetchClubMeetingQuery } from '@repositories/clubs';

// Types
import {
  FetchClubMeetingQuery,
  FetchClubMeetingQueryVariables,
} from '@typings/operations';

export const useFetchClubMeeting = (clubId: string) =>
  useQuery<FetchClubMeetingQuery, FetchClubMeetingQueryVariables>(
    fetchClubMeetingQuery,
    {
      variables: { clubId },
    }
  );
