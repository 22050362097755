import { useQuery } from '@apollo/client';

import { fetchClubContactsQuery } from '@repositories/clubs';

// Types
import {
  FetchEditClubContactQuery,
  FetchEditClubContactQueryVariables,
} from '@typings/operations';

export const useFetchClubContacts = (clubId: string) =>
  useQuery<FetchEditClubContactQuery, FetchEditClubContactQueryVariables>(
    fetchClubContactsQuery,
    { variables: { clubId } }
  );
