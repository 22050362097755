import * as React from 'react';

import classNames from 'classnames';

import { Icon } from '../Icon';
import Link from '../Link';

/**
 * By default, this component uses an HTML `<button>`. To use an `<a>` element,
 * specify the URL with the `to` prop.
 */
export const Button: React.FC<{
  children: React.ReactNode;
  disabled?: boolean;
  full?: boolean;
  clickHandler?: () => void;
  secondary?: boolean;
  icon?: string;
  text?: boolean;
  textNormal?: boolean;
  textSearch?: boolean;
  className?: string;
  to?: string;
  small?: boolean;
  alignLeft?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  mapButton?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  type?: 'submit' | 'reset' | 'button';
  buttonRef?: React.RefObject<HTMLButtonElement> | null;
  dataTestId?: string;
  isMinWidth?: boolean;
  hasNoFocus?: boolean;
  primaryButton?: boolean;
}> = ({
  children,
  disabled,
  full,
  clickHandler,
  secondary,
  icon,
  text: isText,
  textNormal,
  textSearch,
  className = '',
  to,
  small,
  alignLeft,
  capitalize,
  uppercase,
  mapButton,
  state,
  type = 'submit',
  buttonRef = null,
  dataTestId = '',
  isMinWidth = true,
  hasNoFocus = false,
  primaryButton = false,
}) => {
  let buttonIcon;
  const large = !textNormal && !small;
  const text = isText || textNormal;

  const isSecondary = !text && !disabled && secondary;

  if (icon) {
    const size = small ? '14' : '16';
    buttonIcon = (
      <Icon
        name={icon}
        size={size}
        className={classNames('inline-block mr-3 align-middle', {
          'mb-1': !small,
        })}
      />
    );
  }
  const classes = classNames(
    'inline-block tracking-wide w-full tablet:w-auto transition ease-in-out duration-150 font-bold',
    {
      uppercase: typeof uppercase === 'boolean' ? uppercase : !capitalize,
      capitalize,
      'min-w-full': full && isMinWidth,
      'min-w-16': large && !full && isMinWidth,
      'min-w-20': mapButton && !full && isMinWidth,
      'justify-center text-center': !alignLeft,
      'text-bright-blue-600 hover:underline focus:underline active:text-dark-blue-400':
        text && !disabled,
      'text-bright-blue-400 cursor-not-allowed': text && disabled,
      'px-6 rounded-full': !text,
      'text-2xs': small,
      'py-3': !text && !large,
      'py-4': !text && large,
      'text-bright-blue-600 border border-bright-blue-600 hover:bg-bright-blue-600 hover:text-white focus:bg-bright-blue-600 focus:text-white active:bg-bright-blue-600 active:text-bright-blue-400':
        isSecondary && !hasNoFocus,
      'text-white bg-bright-blue-600 hover:bg-dark-blue-400 focus:bg-dark-blue-400 active:bg-dark-blue-400 active:text-dark-blue-200':
        !text && !disabled && !secondary && !primaryButton,
      'bg-gray-300 cursor-not-allowed text-white': !text && disabled,
      'text-bright-blue-600 border border-bright-blue-600 hover:bg-bright-blue-600 hover:text-white focus:shadow-none':
        isSecondary && hasNoFocus,
      'rounded text-white bg-dark-blue-500 hover:bg-dark-blue-400 focus:bg-dark-blue-400 active:bg-dark-blue-400 active:text-dark-blue-400': primaryButton,
    }
  );

  const textSearchClasses = classNames(
    'inline-block tracking-wide tablet:w-2/4 text-xs font-normal',
    {
      uppercase: !capitalize,
      capitalize,
      'text-dark-blue-400 hover:underline focus:underline active:text-bright-blue-400':
        textSearch && !disabled,
      'text-bright-blue-400 cursor-not-allowed': textSearch && disabled,
    }
  );

  const disabledLinkHandler = (e: React.MouseEvent): void => {
    e.preventDefault();
  };
  return to ? (
    <Link
      to={to}
      onClick={disabled ? disabledLinkHandler : clickHandler}
      noLinkStyles
      className={`${classes} ${className}`}
      state={state}
    >
      {buttonIcon}
      {children}
    </Link>
  ) : (
    <button
      data-testid={dataTestId}
      ref={buttonRef}
      disabled={disabled}
      onClick={clickHandler}
      className={
        textSearch
          ? `${textSearchClasses} ${className}`
          : `${classes} ${className}`
      }
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {buttonIcon}
      {children}
    </button>
  );
};
