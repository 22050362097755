import { gql } from '@apollo/client';

export const meetingFieldsFragment = gql`
  fragment MeetingFields on Meeting {
    name
    language
    languageId
    comment
    time
    weekday
    frequency
    type
    locationVaries
    locationNameOrDetail
    address {
      lineOne
      lineTwo
      lineThree
      country
      countryId
      city
      state
      stateId
      postalCode
      internationalProvince
    }
    detailForJoiningRemotely
    onlineLocation
    latitude
    longitude
    meetingId
  }
`;

export const fetchClubMeetingQuery = gql`
  query fetchClubMeeting($clubId: String!) {
    clubById(clubId: $clubId) {
      meetings {
        ...MeetingFields
      }
    }
    countries {
      id
      name
      shortCode
    }
  }
  ${meetingFieldsFragment}
`;
