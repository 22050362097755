import { gql } from '@apollo/client';

export const addClubMeetingQuery = gql`
  mutation addClubMeeting($clubId: String!, $meeting: MeetingInput!) {
    addClubMeeting(input: { clubId: $clubId, meeting: $meeting }) {
      meetingId
      meeting {
        name
      }
    }
  }
`;
