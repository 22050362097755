import { PromotionsCard as PromotionsCardNPM } from '@rotaryintl/component-generic-hero';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const PromotionCard = ({ fields: { Title, Promotions, Columns } = {} }) => (
  <PromotionsCardNPM
    title={<Text field={Title} />}
    columns={Columns?.fields?.Value?.value}
    promotions={Promotions?.map(({ fields: { Title, Description } = {} }) => ({
      title: <Text field={Title} />,
      description: <RichText field={Description} />,
    }))}
  />
);
export default withSitecoreContext()(PromotionCard);