import React from 'react';
import { useState, useEffect } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ClubDistrictCards as ClubAndDistrictCardsNPM } from '@rotaryintl/component-account-hero';
import { MyRotary_getAllClubs, MyRotary_getAllDistrcits } from '../../utilities/helper-functions';
import { GetFormattedDate } from '../../utilities/date-helper-functions';

const HomeAuthenticatedHeroClubAndDistrictCards = (props) => {
  const contextLanguage = props.sitecoreContext?.language.toLowerCase();

  const [allClubs, setAllClubs] = useState({});
  useEffect(async () => {
    const allClubsResponse = await MyRotary_getAllClubs(contextLanguage);
    setAllClubs(allClubsResponse);
  }, []);

  const [allDistricts, setAllDistricts] = useState({});
  useEffect(async () => {
    const allDistrictsResponse = await MyRotary_getAllDistrcits(contextLanguage);
    setAllDistricts(allDistrictsResponse);
  }, []);

  if (allClubs?.length > 0 && allDistricts?.length > 0) {
    return (
      <ClubAndDistrictCardsNPM
        clubs={allClubs}
        districts={allDistricts}
        clubLinkText={<Text field={props.fields?.ViewClubLinkText} />}
        districtLinkText={<Text field={props.fields?.ViewDistrictLinkText} />}
        nextMeetingLabel={<Text field={props.fields?.NextMeetingLabel} />}
        zoneLabel={<Text field={props.fields?.ZoneLabel} />}
        districtLabel={<Text field={props.fields?.DistrictLabel} />}
      />
    );
  } else {
    return <></>;
  }
};

export default withSitecoreContext()(HomeAuthenticatedHeroClubAndDistrictCards);