import { RIBI_COUNTRY_LIST } from '@domain/common';
import { log } from '@hooks/logger';
import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';
import axios from 'axios';

export const getDailyClubBalanceReport = async (clubId: string) => {
  const fetchClubInvoiceDataQuery = `
  query fetchClubInvoiceData($clubId: String!) {
    club: clubById(clubId: $clubId) {
      clubType
      riClubId
      operationsAccess {
        leaderships
      }
      physicalLocation {       
        country
      }
    }
  }
  `;

  const graphqlQuery = `
    query fetchClubBalanceReport($orgType: OrgType!, $orgId: String!) {
      clubBalanceReport: getClubBalanceReport(orgType: $orgType, orgId: $orgId) {
        FileContent
      }
    }
  `;

  const getGraphqlEndPoint = (env: string) => {
    switch (env) {
      case 'local':
        return `http://my-api.rotary.local:4000/graphql`;
      case 'prod':
        return `https://my-api.rotary.org/api/graphql`;
      default:
        return `https://my-api${env}.rotary.org/api/graphql`;
    }
  };

  const graphqlEndpoint = getGraphqlEndPoint(process.env.GATSBY_ENV as string);

  try {
    // Fetch club invoice data
    const response = await axios.post(
      graphqlEndpoint,
      {
        query: fetchClubInvoiceDataQuery,
        variables: { clubId },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    const data = response.data.data.club;
    const isManager = data.operationsAccess.leaderships === 'Manager';
    const clubCountry = data?.physicalLocation?.country || '';
    const isRibiCountry =
      RIBI_COUNTRY_LIST.includes(clubCountry) &&
      data.clubType === DIS.ClubTypeEnum.RotaryClub;

    if (isManager && !isRibiCountry) {
      const pdfVariables = {
        orgType: data.clubType,
        orgId: data.riClubId,
      };

      try {
        const pdfResponse = await axios.post(
          graphqlEndpoint,
          {
            query: graphqlQuery,
            variables: pdfVariables,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        const pdfData = pdfResponse.data.data.clubBalanceReport;

        if (pdfData) {
          const pdfWindow = window.open('', '_blank');
          if (pdfWindow) {
            pdfWindow.document.write(
              `<iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURIComponent(
                pdfData.FileContent ?? ''
              )}"></iframe>`
            );
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          log({
            level: LogLevel.Debug,
            message: `Gatsby: clubBalanceReport, 'GraphQL Request Failed:', ${error.message}`,
          });
        } else {
          log({
            level: LogLevel.Debug,
            message: `Gatsby: clubBalanceReport, 'Unknown error occurred:', ${error}`,
          });
        }
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchClubInvoiceData, 'GraphQL Request Failed:', ${error.message}`,
      });
    } else {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchClubInvoiceData, 'Unknown error occurred:', ${error}`,
      });
    }
  }
};
