import { gql } from '@apollo/client';

import { meetingFieldsFragment } from '@repositories/clubs/queries';

export const fetchClubDetailsQuery = gql`
  query fetchClubDetails($clubId: String!, $termStartYear: Int) {
    club: clubById(clubId: $clubId) {
      riClubId
      clubName
      clubType
      organizationBase
      district
      districtId
      charteredDate
      primaryFax {
        number
        extension
        countryId
      }
      primaryEmail
      primaryPhone {
        number
        extension
        countryId
      }
      primaryWebsite
      officialLanguage {
        name
      }
      mailingAddress {
        lineOne
        lineTwo
        lineThree
        country
        countryId
        city
        state
        stateId
        postalCode
      }
      physicalLocation {
        state
        internationalProvince
        country
      }
      leaders(termStartYear: $termStartYear) {
        primaryEmail
        individualName
        startDate
        endDate
        role {
          name
        }
        individualId
        photoUri
      }
      childClubRelationships {
        clubName
        childClubId
        type
        endDate
      }
      parentClubRelationships {
        clubName
        parentClubId
        type
        endDate
      }
      meetings {
        ...MeetingFields
      }
      operationsAccess {
        club
        memberships
        leaderships
        financesGoalsReports
      }
      vendors {
        vendorName
        startDate
      }
    }
  }
  ${meetingFieldsFragment}
`;
