export function GetFormattedDate(lang, date) {
  const langLocaleMap = {
    en: 'en-us',
    de: 'de-de',
    fr: 'fr-fr',
    it: 'it-it',
    ja: 'ja-jp',
    ko: 'ko-kr',
    pt: 'pt-br',
    ez: 'es-419',
  };

  const options = {
    short: { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' },
  };

  const formattedDate = {
    'en-us': date.toLocaleDateString('en-au', options.short),
    'de-de': date.toLocaleDateString('de-de', options.short),
    'fr-fr': date.toLocaleDateString('fr-fr', options.short),
    'it-it': date.toLocaleDateString('it-it', options.short),
    'ja-jp': date.toLocaleDateString('ja-jp', options.short),
    'ko-kr': date.toLocaleDateString('ko-kr', options.short),
    'pt-br': date.toLocaleDateString('pt-br', options.short).replace(new RegExp(/de /, 'g'), ''),
    'es-419': date.toLocaleDateString('es-xl', options.short),
  };

  return formattedDate[langLocaleMap[lang] ?? lang];
}