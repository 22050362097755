import { DISTRICT_TABS, IsManager } from '@domain/districts';

import { FEATURE_LEADS_MML, isEnabled } from '@utils/features';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

const isMembershipCandidatesFeature = isEnabled(FEATURE_LEADS_MML);

export const useTabsPermissions = (
  isManager: IsManager,
  {
    dashboardsAccess: hasPermissionToDashboard,
    conferencesAccess: hasPermissionToConference,
    leadsAccess: hasPermissionToLeads,
  }: { [key: string]: Boolean }
) => {
  const { i18n } = useTranslation();

  const {
    data: reportsData,
    error: reportsError,
    loading: reportsLoading,
  } = useMenu('menu-district-admin-reports', i18n.language, true, false);

  const {
    data: financeData,
    error: financeError,
    loading: financeLoading,
  } = useMenu('menu-district-admin-finances', i18n.language, true, false);

  const hasPermissionToReports = reportsData?.length > 0 && !reportsError;
  const hasPermissionToFinance = financeData?.length > 0 && !financeError;
  // use nowTerm as it is defined only once and is constant
  // Dashboard tab should be visible only for DO for the current year
  const allowedTabs = [
    {
      hasPermissions: hasPermissionToReports,
      name: DISTRICT_TABS.REPORTS,
    },
    {
      hasPermissions: hasPermissionToFinance,
      name: DISTRICT_TABS.FINANCE,
    },
    {
      hasPermissions: hasPermissionToConference,
      name: DISTRICT_TABS.CONFERENCES,
    },
    {
      hasPermissions: hasPermissionToDashboard,
      name: DISTRICT_TABS.DASHBOARD,
    },
    {
      hasPermissions: hasPermissionToLeads && isMembershipCandidatesFeature,
      name: DISTRICT_TABS.MEMBERSHIPCANDIDATE,
    },
  ]
    .filter(el => !el.hasPermissions)
    .map(el => el.name);

  return {
    reportsData,
    financeData,
    isPermissionsLoading: reportsLoading || financeLoading,
    hasPermissionToReports,
    hasPermissionToFinance,
    hasPermissionToConference,
    hasPermissionToDashboard,
    hasPermissionToLeads,
    allowedTabs,
  };
};
