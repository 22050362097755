import { isNull } from 'lodash';

import {
  ClubContactInfoFormValues,
  ContactAddress,
  EmailOutput,
  WebsiteOutput,
} from '@domain/clubs';

import {
  BaseAddressInput,
  BasicLanguageInput,
  Fax,
  PhoneInput,
  PhoneNumber,
  UpdateClubInput,
} from '@typings/graphql';

const mapPhoneField = (
  primaryPhone?: PhoneNumber | Fax,
  initialPrimaryPhone?: PhoneNumber | Fax
): PhoneInput | null => {
  if (initialPrimaryPhone?.id) {
    if (primaryPhone?.number) {
      return {
        ...primaryPhone,
        action: 'Update',
        id: initialPrimaryPhone.id,
      };
    }
    return {
      number: initialPrimaryPhone?.number || '',
      countryId: initialPrimaryPhone?.countryId || '',
      extension: isNull(initialPrimaryPhone?.extension)
        ? ''
        : initialPrimaryPhone?.extension,
      action: 'Delete',
      id: initialPrimaryPhone?.id,
    };
  }
  if (primaryPhone?.number) {
    return {
      number: primaryPhone?.number || '',
      countryId: primaryPhone?.countryId || '',
      extension: primaryPhone?.extension, // isNull(primaryPhone?.extension) ? '' : primaryPhone?.extension,
      action: 'Add',
    };
  }
  return null;
};

const mapActionField = (
  initial?:
    | BasicLanguageInput
    | EmailOutput
    | WebsiteOutput
    | ContactAddress
    | null,
  updatedValue?: string
): string => {
  if (initial) {
    return updatedValue ? 'Update' : 'Delete';
  }
  return 'Add';
};

export default (
  values: ClubContactInfoFormValues,
  initialValues: ClubContactInfoFormValues
): Omit<UpdateClubInput, 'clubId'> => {
  const {
    address,
    primaryWebsite,
    primaryFax,
    primaryPhone,
    primaryEmail,
    officialLanguage,
  } = values;

  const {
    address: initialAddress,
    primaryFax: initialPrimaryFax,
    primaryPhone: initialPrimaryPhone,
    officialLanguage: initialOfficialLanguage,
    email: initialPrimaryEmail,
    website: initialPrimaryWebsite,
  } = initialValues;

  const updatedAddress = {
    ...address,
  };
  delete updatedAddress.hasStates;
  return {
    mailingAddress: {
      ...(updatedAddress?.lineOne
        ? {
            ...(updatedAddress as BaseAddressInput),
            action: mapActionField(initialAddress, updatedAddress?.lineOne),
          }
        : {
            country: initialAddress?.country,
            countryId: initialAddress?.countryId || '',
            city: initialAddress?.city || '',
            postalCode: initialAddress?.postalCode,
            lineOne: initialAddress?.lineOne || '',
            lineTwo: initialAddress?.lineTwo || '',
            lineThree: initialAddress?.lineThree,
            state: initialAddress?.state,
            id: initialAddress?.id,
            action: 'Delete',
            stateId: initialAddress?.stateId,
            internationalProvince: initialAddress?.internationalProvince,
          }),
    },
    primaryEmail: primaryEmail || null,
    primaryWebsite: primaryWebsite || null,
    primaryPhone: mapPhoneField(primaryPhone, initialPrimaryPhone),
    primaryFax: mapPhoneField(primaryFax, initialPrimaryFax),
    officialLanguage: {
      languageId: officialLanguage.languageId,
      id: initialOfficialLanguage?.id,
      action: mapActionField(
        initialOfficialLanguage,
        officialLanguage?.languageId
      ),
    },
    email: {
      action: mapActionField(initialPrimaryEmail, primaryEmail),
      email: primaryEmail || initialPrimaryEmail?.email,
      id: initialPrimaryEmail?.id,
      isPrimary: true,
    },
    website: {
      primaryWebsite: primaryWebsite || initialPrimaryWebsite?.primaryWebsite,
      id: initialPrimaryWebsite?.id,
      otherWebsites: initialPrimaryWebsite?.otherWebsites,
      action: mapActionField(initialPrimaryWebsite, primaryWebsite),
    },
  };
};
