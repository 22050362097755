import { gql } from '@apollo/client';

export const editMeetingQuery = gql`
  mutation editMeeting(
    $clubId: String!
    $meetingId: String!
    $meeting: MeetingInput!
  ) {
    editMeeting(
      input: { clubId: $clubId, meetingId: $meetingId, meeting: $meeting }
    ) {
      meetingId
      meeting {
        name
      }
    }
  }
`;
