import { useQuery } from '@apollo/client';

import { fetchClubDetailsQuery } from '@repositories/clubs';

import {
  FetchClubDetailsQuery,
  FetchClubDetailsQueryVariables,
} from '@typings/operations';

export const useFetchClubDetails = (clubId: string, termStartYear?: number) =>
  useQuery<FetchClubDetailsQuery, FetchClubDetailsQueryVariables>(
    fetchClubDetailsQuery,
    { variables: { clubId, termStartYear }, fetchPolicy: 'cache-and-network' }
  );
