import * as React from 'react';
import { useRef, useState } from 'react';

import { AccountData } from '@components/Header/types';
import Link from '@components/Link';

import { Avatar } from '../../Avatar';
import { Icon } from '../../Icon';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useProfilePhoto } from '@hooks/profile-photo';
import { useCaptureClickOutside } from '@hooks/useCaptureClickOutside';

const UserMenu: React.FC<{
  className?: string;
  accountInfo: AccountData;
  toggleMenu?: () => void;
}> = ({ className, accountInfo, toggleMenu }) => {
  const { user, logout } = useAppConfig();
  const { t } = useTranslation();
  const { photoUri } = useProfilePhoto();

  const [state, setState] = useState({
    isOpen: false,
  });

  const handleLogout = () => {
    logout();
    window.sessionStorage.removeItem('previousPagePath');
  };
  const handleToggle = () => {
    if (!user?.isLoggedIn) return;

    setState({
      isOpen: !state.isOpen,
    });
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  useCaptureClickOutside(wrapperRef, () => setState({ isOpen: false }));

  return (
    <div
      className={`${className} desktop:h-12 font-normal desktop:relative desktop:z-60 normal-case desktop:flex desktop:items-center`}
    >
      <div
        id="userMenu"
        className="flex items-center cursor-pointer"
        onClick={handleToggle}
        ref={wrapperRef}
      >
        <Avatar
          alt={user?.login || ''}
          size="small"
          photoUri={photoUri || undefined}
        />
        <div data-cy="userInfo">
          {accountInfo?.firstName} {accountInfo?.lastName}
          {user?.isLoggedIn && (
            <div className="flex items-center desktop:hidden">
              <small className="font-normal">
                {t('global.user.my-account-label', 'My account')}
              </small>
              <Icon
                className="ml-2"
                name="arrows/down"
                size="8"
                color="white"
              />
            </div>
          )}
        </div>
        {user?.isLoggedIn && (
          <Icon
            className="ml-2 hidden desktop:block"
            name="arrows/down"
            size="10"
            color="white"
          />
        )}
        {state.isOpen && (
          <div className="desktop:hidden opacity-50 flex flex-1 justify-end">
            <Icon
              className="cursor-pointer"
              name="ui/close"
              size="16"
              color="white"
            />
          </div>
        )}
      </div>
      {state.isOpen && (
        <div className="userMenu desktop:absolute desktop:bottom-0 desktop:right-0 desktop:z-60 w-100 bg-dark-blue-600 pt-6 desktop:pb-6 desktop:px-6 text-left cursor-default">
          <div className="flex justify-between items-center opacity-50 mb-6">
            <h4 className="m-0 h4 alternate font-light">
              {t('global.user.my-account-label', 'My account')}
            </h4>
            <div onClick={handleToggle}>
              <Icon
                className="cursor-pointer hidden desktop:block"
                name="ui/close"
                size="12"
                color="white"
              />
            </div>
          </div>
          <nav>
            <div onClick={handleToggle}>
              <ul>
                <li className="mt-4 first:mt-0">
                  <Link
                    className="text-white"
                    to={`/profile/${user?.individualId}`}
                    onClick={() => (toggleMenu ? toggleMenu() : null)}
                  >
                    {t('global.user.my-profile-link-label', 'My Profile')}
                  </Link>
                </li>
                <li className="mt-4 first:mt-0">
                  <Link
                    className="text-white"
                    to="/user/donations/management"
                    onClick={() => (toggleMenu ? toggleMenu() : null)}
                  >
                    {t('global.user.my-donations-link-label', 'My Donations')}
                  </Link>
                </li>
                <li className="mt-4 first:mt-0">
                  <Link
                    className="text-white"
                    to="/account/actions"
                    onClick={() => (toggleMenu ? toggleMenu() : null)}
                  >
                    {t(
                      'global.user.account-actions-link-label',
                      'My Account Actions'
                    )}
                  </Link>
                </li>
                <li className="mt-4 first:mt-0">
                  <Link
                    className="text-white"
                    to="/account/settings"
                    onClick={() => (toggleMenu ? toggleMenu() : null)}
                  >
                    {t(
                      'global.user.account-settings-link-label',
                      'Account Settings'
                    )}
                  </Link>
                </li>
                <li className="mt-4 first:mt-0">
                  <span
                    className="cursor-pointer hover:underline"
                    onClick={handleLogout}
                  >
                    {t('global.user.logout-button-label', 'Sign Out')}
                  </span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
